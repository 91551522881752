<template>
  <CHeader with-subheader style="background-color: #4786ff !important;">
    <CHeaderBrand class="mx-auto d-lg-none" to="/"></CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-12">
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav>
      <TheHeaderDropdownAccnt v-if="!is_mobile" />
      <TheHeaderDropdownMobile v-if="is_mobile" />
    </CHeaderNav>
  </CHeader>
</template>

<script>
import router from '../router/index'
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import TheHeaderDropdownMobile from './TheHeaderDropdownMobile'
import account_ws from '../services/account';
import login from '../services/login';

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt,
    TheHeaderDropdownMobile
  },
  data () {
    return { 
      is_mobile: false
    }
  },
  mounted: async function() {
    var w = window.innerWidth;
    var h = window.innerHeight;

    if(w < 800){
      this.is_mobile = true;
    }
  },
  methods: {
    logout: function(){
      localStorage.removeItem("account");
      localStorage.removeItem("token");

      router.push("login");
    },
  }
}
</script>
